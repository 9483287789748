// BETA Launch Switch
export const BETA_LAUNCH_ON = true

// Free Plan Settings
export const FREE_PLAN_CREDITS = 50
export const FREE_PLAN_SIMULTANEOUS_JOBS = 5

// Standard Plan Settings
export const STANDARD_PLAN_TRIAL_CREDITS = 100
export const STANDARD_PLAN_MONTHLY_CREDITS = 300
export const STANDARD_PLAN_SIMULTANEOUS_JOBS = 50

// Job Input Length Limits
export const MAX_FILE_NAME_LENGTH = 1000
