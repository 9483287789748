import Link from 'next/link'
import clsx from 'clsx'

const baseStyles = {
  solid:
    'group inline-flex items-center justify-center rounded-md py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2',
  outline:
    'group inline-flex border items-center justify-center rounded-md py-2 px-4 text-sm focus:outline-none',
}

const variantStyles = {
  solid: {
    slate:
      'bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900',
    blue: 'bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600',
    indigo:
      'bg-indigo-600 text-white hover:bg-indigo-500 active:bg-indigo-800 active:text-indigo-100 focus-visible:outline-indigo-600',
    orange:
      'bg-roe-orange text-white hover:text-slate-100 hover:bg-orange-400 active:bg-orange-600 active:text-orange-100 focus-visible:outline-orange-500',
    red: 'bg-red-600 text-white hover:bg-red-500 active:bg-red-800 active:text-red-100 focus-visible:outline-red-600',
    green:
      'bg-green-600 text-white hover:bg-green-500 active:bg-green-800 active:text-green-100 focus-visible:outline-green-600',
    white:
      'bg-white text-slate-900 hover:bg-orange-50 active:bg-orange-200 active:text-slate-600 focus-visible:outline-white',
    black:
      'bg-black text-white hover:bg-gray-800 active:bg-gray-900 active:text-gray-200 focus-visible:outline-black',
  },
  outline: {
    slate:
      'border-slate-200 text-slate-700 hover:text-slate-900 hover:border-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:ring-slate-300',
    white:
      'border-slate-700 text-white hover:border-slate-500 active:border-slate-700 active:text-slate-400 focus-visible:outline-white',
  },
}

type VariantKey = keyof typeof variantStyles
type ColorKey<Variant extends VariantKey> =
  keyof (typeof variantStyles)[Variant]

type ButtonProps<
  Variant extends VariantKey,
  Color extends ColorKey<Variant>,
> = {
  variant?: Variant
  color?: Color
} & (
  | (Omit<React.ComponentPropsWithoutRef<typeof Link>, 'color'> & {
      disabled?: undefined
    })
  | (Omit<React.ComponentPropsWithoutRef<'button'>, 'color'> & {
      href?: undefined
    })
)

export function Button<
  Color extends ColorKey<Variant>,
  Variant extends VariantKey = 'solid',
>({ variant, color, className, ...props }: ButtonProps<Variant, Color>) {
  variant = variant ?? ('solid' as Variant)
  color = color ?? ('slate' as Color)

  const variantClassNames = variantStyles[variant][color]
  const baseClassNames = baseStyles[variant]
  const disabledClassNames = 'opacity-50 cursor-not-allowed'
  const allClassNames = [variantClassNames, baseClassNames, className]
  if (props.disabled) {
    allClassNames.push(disabledClassNames)
  }

  className = clsx(allClassNames)

  return typeof props.href === 'undefined' ? (
    <button className={className} {...props} />
  ) : (
    <Link className={className} {...props} />
  )
}
