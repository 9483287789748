import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useEffect } from 'react'

type NotificationPanelProps = {
  title: string
  show: boolean
  onClose: () => void
  children?: React.ReactNode
}

export default function NotificationPanel({
  title,
  show,
  onClose,
  children,
}: NotificationPanelProps) {
  return (
    <Transition show={show}>
      <div className="pointer-events-auto fixed bottom-5 right-5 z-50 overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
        <div className="p-4">
          <div className="flex w-full flex-shrink-0 items-center gap-x-4">
            <div className="text-md flex-grow font-semibold text-gray-900">
              {title}
            </div>
            <button
              type="button"
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => onClose()}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="flex items-start">
            <div className="w-0 flex-1 pt-1">{children}</div>
          </div>
        </div>
      </div>
    </Transition>
  )
}
