'use client'

import { Fragment, useState, useEffect, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  ArrowUturnLeftIcon,
  Bars3Icon,
  ChartBarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CircleStackIcon,
  Cog6ToothIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  HomeIcon,
  MinusCircleIcon,
  QuestionMarkCircleIcon,
  RectangleGroupIcon,
  ServerStackIcon,
  TableCellsIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import useAuth from '@/hooks/useAuth'
import { usePathname, useRouter } from 'next/navigation'
import LabelDivider from '@/components/dividers/LableDevider'
import Spinner from '@/components/Spinner'
import Avatar from '@/components/Avatar'
import { Logo } from '@/components/Logo'
import classNames from '@/lib/common/classNames'
import Intercom from '@intercom/messenger-js-sdk'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import NotificationPanel from '@/components/NotificationPanel'
import {
  createUserFeedback,
  listUserFeedback,
  updateUserFeedback,
} from '@/actions/users'
import { UserFeedback } from '@/lib/common/types'
import { useInterval } from 'usehooks-ts'
import { Textarea } from 'flowbite-react'
import { Button } from '@/components/Button'
import BetaLogo from '@/components/BetaLogo'

enum BreadcrumbsResources {
  'aws-s3' = 'AWS S3',
  'snowflake' = 'Snowflake',
  's3-bucket' = 'S3 Bucket',
}

const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon },
  {
    name: 'Workflows',
    href: '/workflows',
    icon: RectangleGroupIcon,
  },
  {
    name: 'Workspace',
    href: '/workspace',
    icon: TableCellsIcon,
  },
  {
    name: 'Agents',
    href: '/agents',
    icon: ServerStackIcon,
  },
  { name: 'Resources', href: '/resources', icon: CircleStackIcon },
  { name: 'Metrics', href: '/metrics', icon: ChartBarIcon },
]
const disabled_navigation = ['Dashboard', 'Workflows', 'Metrics']

const secondary_navigation = [
  {
    name: 'Settings',
    href: '/settings',
    icon: Cog6ToothIcon,
  },
  {
    name: 'Help',
    icon: QuestionMarkCircleIcon,
    isHelp: true,
  },
  {
    name: 'Sign Out',
    href: '/signout',
    icon: ArrowUturnLeftIcon,
  },
]

const home_href = '/'

const breadcrumbs: {
  [key: string]: { name: string; href: string }
} = {
  workflows: { name: 'Workflows', href: '/workflows' },
  agents: { name: 'Agents', href: '/agents' },
  data: { name: 'Resources', href: '/resources' },
  'roe-datasets': { name: 'Roe Datasets', href: '/resources/roe-datasets' },
  resources: { name: 'Resources', href: '/resources' },
  new: { name: 'New Resource', href: '/resources/new' },
  metrics: { name: 'Metrics', href: '/metrics' },
  settings: { name: 'Settings', href: '/settings' },
}

type BreadcrumbProps = {
  loc_list?: string[]
}

function Breadcrumb({ loc_list = [] }: BreadcrumbProps) {
  const pages: (
    | {
        name: string
        href: string
      }
    | undefined
  )[] = loc_list.map((loc, index, array) => {
    if (breadcrumbs[loc] === undefined) {
      if (index - 1 >= 0 && array[index - 1] === 'agents') {
        return { name: `Agent ${loc.slice(0, 8)}`, href: `/agents/${loc}` }
      }
      if (index - 1 >= 0 && array[index - 1] === 'roe-datasets') {
        return {
          name: `Dataset ${loc.slice(0, 8)}`,
          href: `/resources/roe-datasets/${loc}`,
        }
      }
      if (index === 0 && loc === 'resources') {
        return breadcrumbs[loc]
      }
      if (index === 1 && loc === 'new') {
        return breadcrumbs[loc]
      }
      if (index === 2 && array[index - 1] === 'new') {
        return {
          name: loc.charAt(0).toUpperCase() + loc.slice(1),
          href: `/resources/new/${loc}/${array[index + 1]}`,
        }
      }
      if (index === 1 && loc === 'configure') {
        return undefined
      }
      if (index === 2 && array[index - 1] === 'configure') {
        return {
          name: `Configure ${BreadcrumbsResources[loc as keyof typeof BreadcrumbsResources]}`,
          href: `/resources/configure/${array[2]}/${array[3]}`,
        }
      }

      return undefined
    }
    return breadcrumbs[loc]
  })
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <a href={home_href} className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {pages.map((page, index, array) => {
          if (page === undefined) {
            return null
          }
          return (
            <li key={page.name}>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <a
                  href={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={index + 1 === array.length ? 'page' : undefined}
                >
                  {page.name}
                </a>
              </div>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default function AppLayout({
  children,
  modal,
}: {
  children: React.ReactNode
  modal: React.ReactNode
}) {
  const { isAuthenticated, isAuthenticating, currUser } = useAuth()
  const router = useRouter()
  const pathname = usePathname()

  const isTablePage = pathname === '/workspace'
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sidebarSlim, setSidebarSlim] = useState(isTablePage)

  const [latestFeedback, setLatestFeedback] = useState<
    UserFeedback | undefined
  >()
  const [shouldCollectFeedback, setShouldCollectFeedback] = useState(false)
  const [showFeedback, setShowFeedback] = useState(true)
  const [feedbackObj, setFeedbackObj] = useState<UserFeedback | undefined>()
  const [feedbackAnswer, setFeedbackAnswer] = useState('')

  const FEEDBACK_QUESTION = 'What can we do better?' // TODO : Randomize from a list

  useInterval(() => {
    if (!currUser) {
      return false
    }
    let startDate = new Date(currUser.date_joined)
    if (latestFeedback) {
      startDate = new Date(latestFeedback.created_at)
    }
    const now = new Date()
    const diff = now.getTime() - startDate.getTime()
    const shouldShow = diff > 1000 * 60 * 60 * 24 * 7 // 7 days
    setShouldCollectFeedback(shouldShow)
  }, 1000 * 20) // 20 seconds

  const scoreFeedback = async (score: number) => {
    if (!currUser) {
      return
    }
    const response = await createUserFeedback(score)
    if (!response.ok) {
      return
    }
    const feedback = await response.payload
    setFeedbackObj(feedback)
  }

  const questionFeedback = async (question: string, answer: string) => {
    if (!currUser || !feedbackObj) {
      return
    }
    const response = await updateUserFeedback(
      feedbackObj.id,
      feedbackObj.score,
      question,
      answer,
    )
    if (!response.ok) {
      return
    }
    const feedback = await response.payload
    setFeedbackObj(feedback)
  }

  useEffect(() => {
    if (!isAuthenticated && !isAuthenticating) {
      router.push('/signin')
    }
  }, [isAuthenticated, isAuthenticating, router])

  useEffect(() => {
    const fetchLatestFeedback = async () => {
      const response = await listUserFeedback()
      if (!response.ok) {
        return
      }
      const feedbacks = (await response.payload)?.results || []
      if (feedbacks.length > 0) {
        setLatestFeedback(feedbacks[0])
      }
    }
    fetchLatestFeedback()
  }, [])

  if (isAuthenticating) {
    return (
      <div className="flex min-h-full items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (!isAuthenticated) {
    return null
  }

  if (currUser) {
    Intercom({
      app_id: 'gsiclyx2',
      user_id: currUser.id.toString(), // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      name: (currUser.first_name || '') + (currUser?.last_name || ''), // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      email: currUser.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      created_at: new Date(currUser.date_joined).getTime() / 1000, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      custom_launcher_selector: '#help-intercom',
    })
  }

  return (
    <div className="h-full">
      <ToastContainer />
      <NotificationPanel
        title="How do you feel about our service?"
        show={showFeedback && shouldCollectFeedback}
        onClose={() => {
          questionFeedback(FEEDBACK_QUESTION, feedbackAnswer)
          setShowFeedback(false)
        }}
      >
        {!feedbackObj ? (
          <div className="flex flex-row items-center justify-center gap-x-10 p-4">
            <button
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full hover:bg-gray-100"
              onClick={() => scoreFeedback(5)}
            >
              <HandThumbUpIcon className="h-6 w-6 text-gray-500" />
            </button>
            <button
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full hover:bg-gray-100"
              onClick={() => scoreFeedback(3)}
            >
              <MinusCircleIcon className="h-6 w-6 text-gray-500" />
            </button>
            <button
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full hover:bg-gray-100"
              onClick={() => scoreFeedback(1)}
            >
              <HandThumbDownIcon className="h-6 w-6 text-gray-500" />
            </button>
          </div>
        ) : (
          <div className="flex w-full flex-col items-center gap-1">
            <Textarea
              placeholder={FEEDBACK_QUESTION}
              value={feedbackAnswer}
              onChange={(e) => setFeedbackAnswer(e.target.value)}
              rows={4}
            />
            <Button
              variant="outline"
              onClick={() => {
                questionFeedback(FEEDBACK_QUESTION, feedbackAnswer)
                setShowFeedback(false)
              }}
              className="w-full"
            >
              Submit
            </Button>
          </div>
        )}
      </NotificationPanel>
      {/* Mobile sidebar */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center gap-3">
                    <Logo className="h-6 w-auto" />
                    <BetaLogo />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => {
                            const isCurrent = item.href === pathname
                            const isDisabled = disabled_navigation.includes(
                              item.name,
                            )
                            if (isDisabled) {
                              return (
                                <li key={item.name}>
                                  <a className="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-300">
                                    <item.icon
                                      className="h-6 w-6 shrink-0 text-gray-200"
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </a>
                                </li>
                              )
                            }
                            return (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    isCurrent
                                      ? 'bg-gray-50 text-indigo-600'
                                      : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      isCurrent
                                        ? 'text-indigo-600'
                                        : 'text-gray-400 group-hover:text-indigo-600',
                                      'h-6 w-6 shrink-0',
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            )
                          })}
                        </ul>
                      </li>
                      <LabelDivider />
                      <li>
                        <ul role="list" className="-mx-2 mt-2 space-y-1">
                          {secondary_navigation.map((item) => {
                            const isCurrent = item.href === pathname
                            return (
                              <li key={item.name}>
                                <a
                                  id={item.isHelp ? 'help-intercom' : undefined}
                                  href={item.href}
                                  className={classNames(
                                    isCurrent
                                      ? 'bg-gray-50 text-indigo-600'
                                      : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      isCurrent
                                        ? 'text-indigo-600'
                                        : 'text-gray-400 group-hover:text-indigo-600',
                                      'h-6 w-6 shrink-0',
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            )
                          })}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={classNames(
          'relative hidden duration-300 ease-in-out lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:flex-col',
          sidebarSlim ? 'lg:w-16' : 'lg:w-64',
        )}
      >
        {/* Sidebar toggle, controls the 'sidebarOpen' state. */}
        <div
          className="absolute right-0 top-5 z-40 flex h-6 w-6 translate-x-1/2 transform items-center justify-center rounded-full bg-gray-100 hover:cursor-pointer"
          onClick={() => setSidebarSlim(!sidebarSlim)}
        >
          {sidebarSlim ? (
            <ChevronRightIcon
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          ) : (
            <ChevronLeftIcon
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          )}
        </div>

        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div
          className={classNames(
            'flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white',
            sidebarSlim ? 'px-2' : 'px-6',
          )}
        >
          <div className="flex h-16 shrink-0 items-center gap-3">
            <Logo className="h-6 w-auto" symbolOnly={sidebarSlim} />
            {!sidebarSlim && <BetaLogo />}
          </div>
          {sidebarSlim && <BetaLogo className="-mt-4 text-xs" />}
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation
                    .filter((item) => {
                      return !disabled_navigation.includes(item.name)
                    })
                    .map((item) => {
                      const isCurrent = item.href === pathname
                      // const isDisabled = disabled_navigation.includes(item.name)
                      // if (isDisabled) {
                      //   return (
                      //     <li key={item.name}>
                      //       <a className="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400">
                      //         <item.icon
                      //           className="h-6 w-6 shrink-0 text-gray-200"
                      //           aria-hidden="true"
                      //         />
                      //         {item.name}
                      //       </a>
                      //     </li>
                      //   )
                      // }
                      return (
                        <li key={item.name}>
                          <a
                            href={item.href}
                            className={classNames(
                              isCurrent
                                ? 'bg-gray-50 text-indigo-600'
                                : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                              'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                              sidebarSlim ? 'justify-center' : '',
                            )}
                          >
                            <item.icon
                              className={classNames(
                                isCurrent
                                  ? 'text-indigo-600'
                                  : 'text-gray-400 group-hover:text-indigo-600',
                                'h-6 w-6 shrink-0',
                              )}
                              aria-hidden="true"
                            />
                            {sidebarSlim ? '' : item.name}
                          </a>
                        </li>
                      )
                    })}
                </ul>
              </li>
              <LabelDivider />
              <li>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {secondary_navigation.map((item) => {
                    const isCurrent = item.href === pathname
                    return (
                      <li key={item.name}>
                        <a
                          id={item.isHelp ? 'help-intercom' : undefined}
                          href={item.href}
                          className={classNames(
                            isCurrent
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                            sidebarSlim ? 'justify-center' : '',
                          )}
                        >
                          <item.icon
                            className={classNames(
                              isCurrent
                                ? 'text-indigo-600'
                                : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0',
                            )}
                            aria-hidden="true"
                          />
                          {sidebarSlim ? '' : item.name}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </li>
              <li className="-mx-6 mt-auto">
                <a
                  href="/settings"
                  className={classNames(
                    'flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50',
                    sidebarSlim ? 'justify-center' : '',
                  )}
                >
                  <Avatar
                    size="small"
                    firstName={currUser?.first_name}
                    lastName={currUser?.last_name}
                  />
                  {!sidebarSlim && (
                    <>
                      <span className="sr-only">Your profile</span>
                      <span aria-hidden="true">
                        {currUser?.first_name} {currUser?.last_name}
                      </span>
                    </>
                  )}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex flex-1 items-center gap-2">
          <span className="text-sm font-semibold leading-6 text-gray-900">
            Dashboard
          </span>
          <BetaLogo className="text-xs" />
        </div>
        <a href={home_href}>
          <span className="sr-only">Your profile</span>
          <Avatar
            size="small"
            firstName={currUser?.first_name}
            lastName={currUser?.last_name}
          />
        </a>
      </div>
      <main
        className={classNames(
          'h-full',
          sidebarSlim ? 'lg:pl-16' : 'lg:pl-64',
          isTablePage ? '' : 'py-8',
        )}
      >
        <div
          className={classNames(
            'flex h-full flex-col',
            isTablePage ? '' : 'px-4 sm:px-6 lg:px-8',
          )}
        >
          {!isTablePage && (
            <div className="mb-8">
              <Breadcrumb loc_list={pathname.split('/').slice(1)} />
            </div>
          )}
          <div className="flex flex-1 flex-col">
            {modal}
            {children}
          </div>
        </div>
      </main>
    </div>
  )
}
