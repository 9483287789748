import { BETA_LAUNCH_ON } from '@/config'
import clsx from 'clsx'

type BetaLogoProps = {
  className?: string
}

export default function BetaLogo({ className }: BetaLogoProps) {
  return (
    BETA_LAUNCH_ON && (
      <div className="flex items-center">
        <span
          className={clsx(
            className,
            'rounded-full bg-blue-500 px-2 py-1 text-sm font-semibold uppercase text-white',
          )}
        >
          Beta
        </span>
      </div>
    )
  )
}
