import React from 'react'

type Props = {
  text?: string
}

const defaultProps: Props = {
  text: undefined,
}

export default function LabelDivider(props: Props = defaultProps) {
  const { text } = props
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      {text && (
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-sm text-gray-500">{text}</span>
        </div>
      )}
    </div>
  )
}
