import clsx from 'clsx'

const sizeStyles = {
  small: 'h-8 w-8 text-sm',
  medium: 'h-10 w-10 text-base',
  large: 'h-12 w-12 text-lg',
}

type SizeKey = keyof typeof sizeStyles

type ButtonProps<Size extends SizeKey> = {
  size?: Size
  alt?: string
  firstName?: string
  lastName?: string
} & (
  | Omit<React.ComponentPropsWithoutRef<'img'>, 'alt'>
  | (React.ComponentPropsWithoutRef<'div'> & {
      src?: undefined
    })
)

export default function Avatar<Size extends SizeKey = 'medium'>({
  size,
  alt,
  firstName,
  lastName,
  className,
  ...props
}: ButtonProps<Size>) {
  size = size ?? ('medium' as Size)
  alt = alt ?? 'Avatar'
  const initials = `${firstName?.charAt(0) ?? ''}${lastName?.charAt(0) ?? ''}`

  const baseClassNames =
    props.src === undefined
      ? 'relative inline-flex items-center justify-center overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600'
      : 'rounded-full'
  const sizeClassNames = sizeStyles[size]
  const allClassNames = [sizeClassNames, baseClassNames, className]
  className = clsx(allClassNames)

  return typeof props.src === 'undefined' ? (
    <div className={className}>
      <span className="font-medium text-gray-600 dark:text-gray-300">
        {initials}
      </span>
    </div>
  ) : (
    // eslint-disable-next-line @next/next/no-img-element
    <img className={className} alt={alt} {...props} />
  )
}
