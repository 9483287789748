'use client'

import Image from 'next/image'
import logoRoeAI from '@/images/logos/logo-roe-ai.svg'
import symbolRoeAI from '@/images/logos/symbol-roe-ai.svg'
import { Transition } from '@headlessui/react'

export function Logo({
  className = '',
  symbolOnly = false,
}: {
  className: string
  symbolOnly?: boolean
}) {
  return (
    <>
      <Transition
        show={symbolOnly}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-0"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Image
          src={symbolRoeAI}
          alt="Roe AI"
          width="0"
          height="0"
          sizes="100vw"
          priority
          className={className}
        />
      </Transition>
      <Transition
        show={!symbolOnly}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-0"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Image
          src={logoRoeAI}
          alt="Roe AI"
          width="0"
          height="0"
          sizes="100vw"
          priority
          className={className}
        />
      </Transition>
    </>
  )
}
